<template>
  <div class="s_layout_sidebar_item">
    <el-menu-item v-if="!item.children" :index="basePath">
      <SvgIcon v-if="item.icon" :name="item.icon"/>
      <span slot="title">{{ item.title }}</span>
    </el-menu-item>

     <el-menu-item v-else-if="item.children.length == 1" :index="resolvePath(item.children[0].path)">
      <SvgIcon v-if="item.icon" :name="item.icon"/>
      <span slot="title">{{ item.title }}</span>
    </el-menu-item>
    
    <el-submenu v-else :index="basePath">
      <template slot="title">
        <SvgIcon v-if="item.icon" :name="item.icon"/>
        <span slot="title">{{ item.title }}</span>
      </template>
      <LayoutSidebarItem v-for="menu in item.children" :key="menu.id" :item="menu" :basePath="resolvePath(menu.path)"/>
    </el-submenu>
  </div>
</template>

<script>
  import path from 'path'
  export default {
    name: 'LayoutSidebarItem',
    props: {
      item: {
        type: Object,
        required: true
      },
      basePath: {
        type: String,
        default: ''
      }
    },
    methods: {
      resolvePath(routePath) {
          if(this.$global.isExternal(routePath)){
              return routePath
          }
          return path.resolve(this.basePath, routePath)
      }
    }
  }
</script>

<style lang="scss">
.s_layout_sidebar_item {
  .el-submenu__title {
    display: flex;
    align-items: center;
  }
  .el-menu-item {
    .svg-icon {
      font-size: 20px;
      color: $menuText;
    }
    &.is-active {
      background: $menuActiveBg !important;
      color: $white!important;
      .svg-icon {
       color: $white;
      }
    }
  }
}
</style>