<template>
  <el-aside class="s_layout_sidebar g-flex-column" :width="isCollapse ? '60px' : '200px'">
    <div class="g-flex-align-center s-layout-sidebar-logo g-flex-justify-center">
      <img class="g-img-cover s-layout-sidebar-img" src="/img/logo3.png" />
    </div>
    <div class="s-layout-slibar-scrollbar g-self-scrollbar">
        <el-menu :default-openeds="openeds" tag="div" class="g-self-scrollbar" mode="vertical" :default-active="$route.path" @select="menuActive" :collapse="isCollapse" :background-color="menuBg" :text-color="menuText" :active-text-color="menuActiveText">
            <LayoutSidebarItem v-for="menu in MenuList" :key="menu.id" :item="menu" :basePath="menu.path" />
        </el-menu>
    </div>
  </el-aside>
</template>

<script>
import LayoutSidebarItem from './LayoutSidebarItem.vue'
import { menuBg, menuText, menuActiveText } from "@/assets/css/export.scss";
  export default {
    components: { LayoutSidebarItem },
    data() {
      return {
        MenuList: this.$global.MenuList,
        user: this.$global.adminUser,
        menuBg: menuBg,
        menuText: menuText,
        menuActiveText: menuActiveText
      }
    },
    computed: {
      openeds() {
        if(this.$global.adminUser == {}) return []
        if(this.$global.adminUser.type == 2) return ['/issuetask', '/moneystore']
        return []
      }
    },
    props: {
      //左侧菜单栏收缩
      isCollapse: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      menuActive(index) {
        if (this.$global.isExternal(index)) {
          window.open(index, "_blank");
        } else {
          this.$router.push(index).catch(() => {
            //重复路由跳转刷新
            this.$router.push("/refresh");
          });
        }
      },
    }
  }
</script>

<style lang="scss">
.s_layout_sidebar {
  height: 100%;
  background: $menuBg;
  transition: all 0.28s; //加上动画效果

  .el-menu--collapse { //收缩时是这个类（加上才有动画效果）
    width: 100%;
    // 收缩时左侧菜单高度动画
    .el-submenu__title {
      span {
        height: 0;
        width: 0;
        overflow: hidden;
        visibility: hidden;
        display: inline-block;
      }
      i {
        &::before {
          content: ''
        }
      }
    }

    // 收缩时左侧菜单图标背景颜色和字体颜色
    .el-submenu {
      &.is-active {
        .el-submenu__title {
          color: $white!important;
          background: $menuActiveBg!important;;
        }
      }
    }
  }

  .s-layout-sidebar-logo {
    padding: 20px 0;
    .s-layout-sidebar-img {
      height: 70px;
      object-fit: contain;
      vertical-align: middle;
    }
    .s-layout-sidebar-title {
      color: $white;
      font-size: 14px;
      padding-left: 10px;
      @include textEllipsis();
    }
  }
  .s-layout-slibar-scrollbar {
    flex: 1;
    overflow: auto;
    background: $menuBg;
    .el-menu {
      width: 100%;
      border: none;
      .svg-icon {
        width: 18px;
        height: 18px;
        margin-right: 5px;
      }
    }
  }
}

// 菜单收缩的时候展示二级菜单的那个弹窗
.el-menu--popup {
  padding: 0;
}
</style>