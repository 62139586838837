<template>
  <div class="s_layout_header">
    <div class="s-layout-tabs g-flex-align-center">
      <!-- 收缩 -->
      <div class="g-flex-align-center g-flex-justify-center s-fold" @click="$emit('emitcollapse')">
          <SvgIcon :class="isCollapse ? 'fold-active':''" name="fold" />
      </div>
      <!-- 标签卡 -->
      <el-tabs class="s-substance" v-model="tabPath" type="card" closable @tab-click="clickTab" @tab-remove="removeTab">
          <el-tab-pane v-for="item in tabsList" :key="item.path" :label="item.title" :name="item.path"></el-tab-pane>
      </el-tabs>
      <!-- 个人操作 -->
      <div class="s-layout-header-person g-flex-align-center">
        <!-- 余额 -->
        <div class="s-layout-header-person-balance g-flex-align-center" v-if="userInfo.type == 2">
          <i class="iconfont iconzhanghuyue"></i>
          <div class="s-layout-header-person-balance-show g-flex-align-center">
            <span class="s-layout-header-person-balance-title">账户余额（元）：</span>
            <p class="s-layout-header-person-balance-value">{{userInfo.principal}}</p>
          </div>
          <div class="s-layout-header-person-balance-recharge g-flex-align-center" @click="$router.push({ path: '/moneystore/moneystorerecharge'})">
            <span class="s-layout-header-person-balance-recharge-title">去充值</span>
            <i class="iconfont iconxiayige"></i>
          </div>
        </div>
        <!-- 用户名 -->
        <div class="s-layout-header-person-user-name g-flex-align-center">
          <i class="iconfont iconren-01"></i>
          <span class="s-layout-header-person-user-name-text">{{ computeUserName }}</span>
        </div>
        <!-- 下拉菜单 -->
        <el-dropdown trigger="hover" @command="handleCommand">
            <span class="s-exit-box g-flex-align-center">
                <SvgIcon class="icon-exit" name="exit" />
            </span>
            <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="cleanData" v-if="userInfo.type == 100"><span>清理缓存</span></el-dropdown-item>
                <el-dropdown-item command="loginOut" :divided="userInfo.type == 100 ? true : false"><span>退出登陆</span></el-dropdown-item>
            </el-dropdown-menu>
        </el-dropdown>
        <!-- 全屏 -->
        <div class="s-layout-header-fullscreen g-flex-align-center" @click="clickFullscreen">
          <SvgIcon :name="isFullscreen?'exit-fullscreen':'fullscreen'" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { apiClearCache } from '../../utils/api.js'
  import screenfull from  'screenfull'
  export default {
    props: {
      //左侧菜单栏收缩
      isCollapse: {
        type: Boolean,
        default:false
      }
    },
    data() {
      return {
        tabIndex: 0,
        isFullscreen: false,
        tabsList: [],
        tabPath: '',
      }
    },
    methods: {
      handleCommand(command) {
        if(command === 'loginOut') return this.loginOutClick()
        return this.apiClearCacheHandel()
      },

      // 退出登录
      async loginOutClick() {
          // const { success, data } = await apiLogout()
          // if(!success) return
          this.$message.success('成功')
          this.$global.logout()
      },

      async apiClearCacheHandel() {
          const { success } = await apiClearCache()
          if(!success) return
          this.$message.success('清除成功')
      },

      //点击全屏
      clickFullscreen() {
        if (!screenfull.isEnabled) {
            this.$message({
                message: '当前浏览器不支持全屏',
                type: 'warning'
            })
            return false
        }
        screenfull.toggle()
        this.isFullscreen = !this.isFullscreen
      },
      addTab() {
        if(this.$route.path=='/refresh') return
        this.tabPath = this.$route.path
        if(this.tabsList.some(tab => tab.path === this.$route.path)) return
        this.tabsList.push({
            title: this.$route.meta.title,
            path: this.$route.path
        })
      },

      clickTab() {
        console.log(this.tabPath)
        this.$router.push(this.tabPath).catch(()=>{})
      },

      removeTab(tabPath) {
        //删除其他标签
        if (this.tabsList.length < 2) {
            this.$message.error('无法关闭最后一个Tab!')
            return
        }
        this.tabsList.some((tab, index) => {
            if (tab.path === tabPath) {
                //tabList删除
                this.tabsList.splice(index, 1)
                if (this.tabPath === tabPath) {
                    if (index === 0) {
                        //删除第一个标签,走第一个标签
                        this.tabPath = this.tabsList[0].path
                    } else {
                        //跳转前一个标签
                        this.tabPath = this.tabsList[index - 1].path
                    }
                    this.$router.push(this.tabPath)
                }
                return true
            }
            return false
        })
      }
    },
    computed: {
      computeUserName() {
        let userInfo = this.$global.adminUser
        if(userInfo == {}) return ''
        if(userInfo.type == 2) return userInfo.mobile
        return userInfo.user_name
      },
      userInfo() {
        let userInfo = this.$global.adminUser
        if(userInfo == {}) return { principal: '', type: 0 }
        return userInfo
      }
    },
    watch: {
        $route: {
          handler() {
            this.addTab()
          },
          immediate: true
        }
    }
  }
</script>

<style lang="scss">
.s_layout_header {
  height: 100%;
  background-color: $white;
  color: #606266;
  line-height: 50px;
  font-size: 12px;
  border-bottom: 1px solid #d8dce5;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);
  padding: 0;
  .s-layout-tabs {
    width: 100%;
    height: 100%;
    .s-fold {
      width: 50px;
      height: 50px;
      font-size: 24px;
      color: #000;
      cursor: pointer;
      svg {
        transition: all 0.5s;
      }
      .fold-active {
        transform: rotate(180deg);
      }
    }
    .s-substance {
      flex: 1;
      overflow: auto;
      padding-right: 20px;
      .el-tabs__header {
        margin-bottom: 0;
      }
    }

    .s-layout-header-person {
      .s-layout-header-person-balance {
        padding-right: 20px;
        .iconzhanghuyue {
          font-size: 32px;
          color: $blue;
        } 
        .s-layout-header-person-balance-show {
          padding-left: 10px;
          font-weight: bold;
          .s-layout-header-person-balance-title {
            font-size: 16px;
            color: #000000;
            padding-right: 10px;
          }
          .s-layout-header-person-balance-value {
            font-size: 22px;
            color: #3B84FE;
            padding-right: 10px;
          }
        }
        .s-layout-header-person-balance-recharge {
          padding: 0 10px;
          color: $blue;
          font-size: 16px;
          cursor: pointer;
          .s-layout-header-person-balance-recharge-title {
            padding-right: 4px;
          }
          .iconxiayige {
            font-size: 16px;
          }
        }
      }
      .s-layout-header-person-user-name {
        color:#000000;
        line-height: 36px;
        .iconfont {
          font-size: 36px;
        }
        .s-layout-header-person-user-name-text {
          font-size: 20px;
          font-weight: bold;
        }
      }
      // 全屏
      .s-layout-header-fullscreen {
        width: 80px;
        height: 100%;
        font-size: 20px;
        cursor: pointer;
        padding: 0 30px 0 20px;
      }

      // 退出
      .el-dropdown {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 20px;
        margin-right: 20px;
        .s-exit-box {
          font-size: 26px;
          .icon-exit {
              color: $red;
          }
          .el-icon-caret-bottom{
              position: absolute;
              top: 25px;
              font-size: 12px;
              color: $red;
              left: -20px;
          }
        }
      }

    }
  }
}

</style>