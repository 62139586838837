<template>
  <div class="v_layout">
    <el-container class="v-layout-container">
      <LayoutSlideBar :isCollapse="isCollapse"/>

      <el-container>
        <el-header style="height: 50px">
         <LayoutHeader @emitcollapse="emitCollapse" :isCollapse="isCollapse"/>
        </el-header>

        <el-main class="v-layout-mian" style="padding: 12px;">
            <transition name="g-fade" mode="out-in">
            <keep-alive :include="keepAliveNameList">
              <router-view/>
            </keep-alive>
            </transition>
        </el-main>
        
      </el-container>
    </el-container>

    <!-- 右侧客服悬浮组件 -->
    <concat-us-pop  v-if="$global.adminUser.type == 2" :kefuInfo="$global.config.kefu"/>
  </div>
</template>

<script>
  import LayoutSlideBar from '@/views/layout/LayoutSlideBar.vue'
  import LayoutHeader from '@/views/layout/LayoutHeader.vue'
  import ConcatUsPop from '@/components/ConcatUsPop.vue';
  import { apiGetWSPrefix } from '@/utils/api.js'
  // import Bus from '@/utils/bus.js'
  export default {
    data() {
      return {
        isCollapse: false,
        wsHeadName: ''
      }
    },
    components: { LayoutSlideBar, LayoutHeader, ConcatUsPop },
    created() {
     this.apiGetWSPrefixHandel()
    },
    methods: {
      // 获取websocket前缀
      async apiGetWSPrefixHandel() {
        const { success, data } = await apiGetWSPrefix()
        if(!success) return
        this.wsHeadName = data.data
        this.$ws.setHeadName(data.data)
        if(this.$global.adminUser.type >= 4) {
          this.joinGroupRechargeInfo()
          this.joinGroupTaskReview()
          this.joinGroupShopReview()
        } else if(this.$global.adminUser.type == 2) {
          this.addFnShopTaskReview()
          this.addFnShopTaskOrderReview()
        }
      },
      // 商家订阅审号服务通知
      addFnShopTaskOrderReview() {
        var that = this
        this.$ws.addFn('shopTaskOrderReview', (data) => {
          this.$notify({
            title: '审核账号通知',
            iconClass: 'iconfont icon-xiaoxi',
            duration: 0,
            dangerouslyUseHTMLString: true,
            message: `<p style="cursor: pointer;width:240px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">
                      您有买家账号需要审核
                      </p>`,
            onClick: function () {
              this.close()
              that.$router.push({ path: '/issuetask/tasklistorder', query: { status: data.status, id: data.id }}).catch(() => {
                that.$router.push("/refresh");
              })
            }
          });
        })
      },
      // 商家订阅任务审核通知
      addFnShopTaskReview() {
        var that = this
        this.$ws.addFn('shopTaskReview', (data) => {
          this.$notify({
            title: data.status == 30 ? '任务审核通过消息': '任务审核不通过消息',
            iconClass: 'iconfont icon-xiaoxi',
            duration: 0,
            dangerouslyUseHTMLString: true,
            message: `<p style="cursor: pointer;width:240px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">
                      ${ data.status == 30 ? '您的任务审核通过了' : '您的任务审核被驳回了'}
                      </p>`,
            onClick: function () {
              this.close()
              that.$router.push({ path: '/issuetask/taskstorelist', query: { status: data.status, id: data.id }}).catch(() => {
                that.$router.push("/refresh");
              })
            }
          });
        })
      },
      // 后台订阅websocket审核店铺通知
      joinGroupShopReview() {
        var that = this
        this.$ws.joinGroup('shopReview', () => {
          this.$notify({
            title: '店铺审核',
            iconClass: 'iconfont icon-xiaoxi',
            duration: 0,
            dangerouslyUseHTMLString: true,
            message: `<p style="cursor: pointer;width:240px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">
                      您有店铺需要审核
                      </p>`,
            onClick: function () {
              this.close()
              that.$router.push({ path: '/moneystore/storelist', query: { status: 1 }}).catch(() => {
                that.$router.push("/refresh");
              })
            }
          });
        })
      },
      // 后台订阅websocket分组充值提醒信息 rechargeInfo
      joinGroupRechargeInfo() {
        var that = this
        this.$ws.joinGroup('rechargeInfo', () => {
          this.$notify({
            title: '充值消息',
            iconClass: 'iconfont icon-xiaoxi',
            duration: 0,
            dangerouslyUseHTMLString: true,
            message: `<p style="cursor: pointer;width:240px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">
                      您有充值订单需要审核
                      </p>`,
            onClick: function () {
              this.close()
              that.$router.push({ path: '/moneystore/moneystorechargelist' }).catch(() => {
                that.$router.push("/refresh");
              })
            }
          });
        })
      },
      // 后台订阅websocket分组任务审核通知 taskReview
      joinGroupTaskReview() {
        var that = this
        this.$ws.joinGroup('taskReview', () => {
          this.$notify({
            title: '任务审核通知',
            iconClass: 'iconfont icon-xiaoxi',
            duration: 0,
            dangerouslyUseHTMLString: true,
            message: `<p style="cursor: pointer;width:240px;overflow:hidden;text-overflow:ellipsis;display:-webkit-box;-webkit-line-clamp: 2;-webkit-box-orient: vertical;">
                      您有任务需要审核
                      </p>`,
            onClick: function () {
              this.close()
              that.$router.push({ path: '/task/tasklist' }).catch(() => {
                that.$router.push("/refresh");
              })
            }
          });
        })
      },
      
      emitCollapse() {
        this.isCollapse = !this.isCollapse
      },
      // 监听页面根元素的点击事件静音全部播放一次
      triggerAllSlicePlay() {
        let obj = document.documentElement|| window.pageYOffset || document.body
        addEventListener(obj, 'click', this.autoPlayHandler);
      },

      // 处理静音全部播放一次
      autoPlayHandler() {
        // 获取根元素
        let obj = document.documentElement|| window.pageYOffset || document.body
          if(this.$global.autoPlayFlag) { //是否静音全部播放一次 是的话移除body的点击事件
            if (obj.removeEventListener) {
                obj.removeEventListener("click",this.autoPlayHandler,false);
            } else {
                // ie8及以下，只支持事件冒泡
                obj.detachEvent("onclick",this.autoPlayHandler);
            }
          }else { //还没有全部静音播放一次 需要进行播放
            this.$refs.AudioPlay.sliceAllPlay()
          }
      }
      
    },
    // 离开房间
    beforeDestroy() {
      if(this.$global.adminUser.type >= 4) {
        this.$ws.init()
      } else if(this.$global.adminUser.type == 2) {
        this.$ws.removeFn(this.wsHeadName + 'shopTaskOrderReview')
        this.$ws.removeFn(this.wsHeadName + 'shopTaskReview')
      }
    },
    computed: {
      // 要缓存的路由
      keepAliveNameList() {
        return ['userList', 'userRealList', 'accountList', 'userCashOutList', 'userMoneyList', 'userCashAccountList']
      }
    }
  }
</script>

<style lang="scss">
.v_layout {
  height: 100%;
  .el-header {
    padding: 0;
  }
  .el-main {
    padding: 0;
  }
  .v-layout-container {
    height: 100%;
  }
  .v-layout-mian {
    background: #F2F5FA;
    position: relative;
    padding-right: 40px!important;
  }
}

</style> 
